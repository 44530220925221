import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Section, { BackgroundColor, TextAlign } from "components/section";
import Step from "components/step";
import Separator from "components/separator";
import ImageBlock from "components/imageBlock";
import CTA from "components/cta";
import image1 from "images/chargement-benne-camion-etcheverry-mindurry.jpg";
import step1 from "images/assessment.png";
import step2 from "images/step2.png";
import step3 from "images/step6.png";
import step4 from "images/step22.png";
import step5 from "images/step3.png";
export const _frontmatter = {
  "meta": {
    "title": "Etcheverry Mindurry | La solution pour la collecte, le tri et la valorisation de vos déchets",
    "description": null
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section background={BackgroundColor.DARK} mdxType="Section">
      <h1>{`La solution tout en un pour vos déchets non dangeureux`}</h1>
      <p>{`Installation classée pour la Protection de l’Environnement (ICPE) depuis fin 2016,
notre centre de tri couvert d’une surface de 700 m2 nous permet d’avoir une
démarche approfondie en termes de revalorisation des déchets.`}</p>
      <p>{`Nous assurons la gestion de l’ensemble des Déchets Industriels Non Dangereux (DIND)
appelés aussi Déchets Industriels Banaux (DIB). `}</p>
      <p>{`Sont concernés les produits`}{` `}{`comme`}{` `}{`:`}</p>
      <ul>
        <li parentName="ul">{`Le bois`}</li>
        <li parentName="ul">{`Les déchets verts`}</li>
        <li parentName="ul">{`Le carton`}</li>
        <li parentName="ul">{`Les gravats`}</li>
        <li parentName="ul">{`La ferraille et les métaux non ferreux`}</li>
        <li parentName="ul">{`Le verre`}</li>
        <li parentName="ul">{`Le plastique`}</li>
      </ul>
      <p>{`Avec notre parc de plus de 250 bennes, nous répondons à vos besoins qu’ils
concernent des déchets de chantier, l’évacuation d’encombrants (déménagement)
ou bien des déchets issus de travaux de jardin.`}</p>
    </Section>
    <ImageBlock content={image1} width={100} mdxType="ImageBlock" />
    <Section background={BackgroundColor.DARK} mdxType="Section">
      <p>{`En fonction de la nature de vos déchets, un devis vous est adressé sous 24 heures.
Il inclut également la prestation de transport, à savoir : la pose, la rotation
si besoin ainsi que l’enlèvement de la benne.`}</p>
      <p><a href="https://entreprendre.service-public.fr/vosdroits/R17000" target="_blank">Une demande d’autorisation</a> sera
à régulariser auprès de votre commune dans le cas où le contenant venait à être
déposé sur la voirie ou à empiéter sur celle-ci.</p>
      <p>{`Après validation des conditions, notre équipe procède à la pose de la benne sous
un délai de 24h.
La rotation, si celle-ci a été prévue, ainsi que l’enlèvement de votre benne peut
également s’opérer sous ce même délai d’intervention.`}</p>
      <p>{`Une fois acheminés vers notre centre de tri, les déchets non dangereux collectés
sur votre site respectent les différentes étapes de notre processus de tri.`}</p>
      <p>{`Les matières valorisées sont acheminées vers nos exutoires partenaires.`}</p>
    </Section>
    <Section textAlign={TextAlign.CENTER} width={60} mdxType="Section">
      <h2>{`Comment louer une benne pour le traitement de vos déchets ?`}</h2>
      <Separator mdxType="Separator" />
      <Step.Blue>1</Step.Blue>
      <p><strong parentName="p">{`ÉTUDE DE VOS`}{` `}{`BESOINS ET`}{` `}{`DEVIS`}</strong></p>
      <ImageBlock content={step1} width={25} mdxType="ImageBlock" />
      <p>{`Volume et type de déchets, emplacement du chantier, durée`}</p>
      <Separator mdxType="Separator" />
      <Step.Blue>2</Step.Blue>
      <p><strong parentName="p">{`PLANIFICATION DE`}{` `}{`L'OPÉRATION`}</strong></p>
      <ImageBlock content={step2} width={25} mdxType="ImageBlock" />
      <p>{`Pose de la benne sous 24h`}</p>
      <Separator mdxType="Separator" />
      <Step.Blue>3</Step.Blue>
      <p><strong parentName="p">{`ROTATION OU ENLÈVEMENT DES `}<a href="https://www.ademe.fr/expertises/dechets/quoi-parle-t/types-dechets/dossier/dechets-non-dangereux/categories-dechets-non-dangereux" target="_blank">{`DÉCHETS NON`}{` `}{`DANGEREUX COLLECTÉS`}</a></strong></p>
      <ImageBlock content={step3} width={25} mdxType="ImageBlock" />
      <p>{`Acheminement des déchets vers notre centre`}{` `}{`de`}{` `}{`tri`}</p>
      <Separator mdxType="Separator" />
      <Step.Blue>4</Step.Blue>
      <p><strong parentName="p">{`PHASE DE TRI SUR NOTRE`}{` `}{`PLATEFORME`}</strong></p>
      <ImageBlock content={step4} width={25} mdxType="ImageBlock" />
      <p>{`Déchargement et tri des déchets`}</p>
      <Separator mdxType="Separator" />
      <Step.Blue>5</Step.Blue>
      <p><strong parentName="p">{`ÉVACUATION DES DÉCHETS VALORISÉS`}</strong></p>
      <ImageBlock content={step5} width={25} mdxType="ImageBlock" />
      <p>{`Transport des déchets valorisés vers les centres de traitement agréés`}</p>
    </Section>
    <Section background={BackgroundColor.BLUE} mdxType="Section">
  <CTA label="Nous contacter" to="/contact" mdxType="CTA">
    Vous souhaitez en savoir plus sur nos services pour les particuliers&nbsp;?
  </CTA>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      